import {
    FETCH_START, FETCH_END,
    SALES_INVOICE_QUERY_LIST, SALES_INVOICE_QUERY_FIELDS_SUCCESS,
    SALES_DATA_QUERY_LABELS_SUCCESS,
    SALES_INVOICE_QUERY_SINGLE_SUCCESS,
    SALES_INVOICE_UPLOAD_SUCCESS,
    ALL_SALES_DATA_LIST_SUCCESS,
    SALES_DATA_DETAILS_SINGLE_SUCCESS,
    SALES_DATA_UPLOAD_SUCCESS,
    PURCHASE_INVOICE_QUERY_LIST,
    PURCHASE_INVOICE_QUERY_FIELDS_SUCCESS,
    PURCHASE_INVOICE_QUERY_SINGLE_SUCCESS,
    PURCHASE_INVOICE_UPLOAD_SUCCESS,
    INCOMING_CLAIMS_QUERY_LIST,
    TARGET_INVOICE_LIST,
    TARGET_INVOICE_QUERY_LIST,
    TARGET_INVOICE_QUERY_FIELDS_SUCCESS,
    COMMITMENT_DATA_SINGLE_SUCCESS,
    TARGET_INVOICE_QUERY_SINGLE_SUCCESS,
    UPLOAD_ELIGIBILITY_RULES_SUCCESS,
    MATERIAL_MASTER_FIELDS_SUCCESS,
    MATERIAL_MASTER_SINGLE_FETCH_SUCCESS,
    CUSTOMER_MASTER_FIELDS_SUCCESS,
    MATERIAL_MASTER_QUERY_LIST,
    MATERIAL_MASTER_UPLOAD_SUCCESS,
    MATERIAL_MASTER_ORGA_DATA,
    MATERIAL_UOM_QUERY_LIST,
    CUSTOMER_MASTER_LIST,
    SUPPLIER_MASTER_ORGA_DATA,
    CUSTOMER_MASTER_SINGLE_FETCH_SUCCESS,
    CUSTOMER_MASTER_XREF_SINGLE_FETCH_SUCCESS,
    UPLOAD_ELIGIBILITY_RULES_CLEAR,

    INDIRECT_SALES_INVOICE_QUERY_LIST,
    INDIRECT_SALES_INVOICE_QUERY_FIELDS_SUCCESS,
    INDIRECT_SALES_INVOICE_QUERY_SINGLE_SUCCESS,

    OUTGOING_CLAIMS_QUERY_LIST,
    CUSTOMER_MASTER_ORG_LIST,
    INCOMING_CLAIM_RESPONSE_FORMAT_FIELDS,
    INCOMING_CLAIM_RESPONSE_FORMAT_SUCCESS,
    OUTGOING_CLAIM_DOWNLOAD_FORMAT_SUCCESS,
    OUTGOING_CLAIM_DOWNLOAD_FORMAT_FIELDS,

    DEMAND_SUPPLY_SINGLE_SUCCESS,
    DEMAND_SUPPLY_SUCCESS,
    DEMAND_SUPPLY_FORMAT_BY_ID_SUCCESS,
    DEMAND_SUPPLY_FORMAT_FIELDS_SUCCESS,
    DEMAND_FORMAT_SUCCESS,
    INVENTORY_DATA_SINGLE_SUCCESS,
    INVENTORY_DATA_SUCCESS,
    INVENTORY_DATA_FORMAT_BY_ID_SUCCESS,
    INVENTORY_DATA_FORMAT_FIELDS_SUCCESS,
    INVENTORY_FORMAT_SUCCESS,
    UPLOAD_FAILURE,
    OFF_INVOICE_COST_ERROR_LIST_SUCCESS,
    OFF_INVOICE_COST_ERROR_LIST_FAILED,
    OFFINVOICE_COST_FORMAT_SUCCESS,
    OFFINVOICE_COST_FORMAT_BY_ID_SUCCESS,
    OFFINVOICE_COST_FORMAT_FIELDS_SUCCESS,
    OFFINVOICE_COST_UPLOAD_SUCCESS,
    SALES_FORECAST_DATA_SUCCESS,
    SALES_FORECAST_SUCCESS,
    COVERAGE_COST_DATA_SUCCESS,
    UPLOAD_COVERAGE_COST_SUCCESS,
    COVERAGE_COST_FORMAT_SUCCESS,
    COVERAGE_COST_FORMAT_BY_ID_SUCCESS,
    COVERAGE_COST_FORMAT_FIELDS_SUCCESS,
    COVERAGE_COST_SINGLE_SUCCESS,
    COMPETITOR_DATA,
    COMPETITOR_DATA_BY_ID,
    COMPETITOR_DATA_FORMAT_SUCCESS,
    COMPETITOR_DATA_FORMAT_BY_ID_SUCCESS,
    COMPETITOR_DATA_FORMAT_FIELDS_SUCCESS,
    COMPETITOR_DATA_UPLOAD_SUCCESS,
    SALES_FORECAST_FORMAT_SUCCESS,
    SALES_FORECAST_FORMAT_BY_ID_SUCCESS,
    SALES_FORECAST_FORMAT_FIELDS_SUCCESS,
    MULTIPLE_CLAIMS_DETAIL_SUCCESS,
    TARGET_DATA_UPLOAD_SUCCESS,
    INVENTORY_DATA_UPLOAD_SUCCESS,
    DEMAND_SUPPLY_UPLOAD_SUCCESS,
    INCOMING_CLAIMS_SUCCESS,
    MASTER_DATA_DASHBOARD_SUCCESS,
    MASTER_DATA_DASHBOARD_TRANSACTION_SUCCESS,
    MASTER_DATA_DASHBOARD_CHANGES_SUCCESS,
    MASTER_OPERATION_DATA_SUCCESS,
    MASTER_DATA_CHART_SUCCESS,
    ALL_INDIRECT_SALES_DATA_LIST_SUCCESS,
    INDIRECT_SALES_DATA_DETAILS_SINGLE_SUCCESS,
    ALL_PURCHASE_SALES_DATA_LIST_SUCCESS,
    PURCHASE_SALES_DATA_DETAILS_SINGLE_SUCCESS,
    MASTER_DATA_CONFIG_SUCCESS,
    APPLICATION_LOGS_SUCCESS,
    BUILD_SALES_DATA_LABELS,
    OUTGOING_CLAIM_GET_BY_ID
} from '../../../actions/DataSetup/DataSetupTransaction/ActionTypes';

const initialState = {
    loading: false,
    salesInvoiceQueryListData: [],
    salesInvoiceQueryFieldsData: [],
    salesInvoiceQueryLabels: [],
    AllSalesDataList: [],
    purchaseInvoiceQuerySingleData: [],
    purchaseInvoiceQueryErrorList: [],
    incomingClaimsAllData: [],
    targetInvoiceListData: [],
    targetInvoiceQueryListData: [],
    targetInvoiceQueryFieldsData: [],
    targetInvoiceQuerySingleData: [],
    uploadEligibilityRulesData: [],
    materialMasterSingleData: {},
    materialMasterQueryFieldsData: [],
    customerMasterQueryFieldsData: [],
    materialMasterListData: [],
    materialUomListData: [],
    materialOrgaData: [],
    materialMasterErrorList: [],
    materialMasterErrorLists: [],
    customerMasterListData: [],
    supplierMasterOrgData: [],
    indirectSalesInvoiceQueryListData: [],
    indirectSalesInvoiceQuerysingleData: [],
    outgoingClaimsAllData: [],
    incomingClaimFormatFields: [],
    incomingClaimFormatData: [],
    outgoingClaimDownloadFormatFields: [],
    outgoingClaimDownloadFormatData: [],
    inventoryData: [],
    inventorySingleData: [],
    inventoryFormatData: [],
    inventoryFormatDataById: [],
    inventoryFormatFieldsData: [],
    demandSupply: [],
    demandSingleData: [],
    demandFormatData: [],
    demandFormatDataById: [],
    demandFormatFieldsData: [],
    uploadFailureData: [],
    offInvoiceCostErrorListData: [],
    offInvoiceCostErrorList: [],
    offInvoiceCostFormatData: [],
    offInvoiceCostFormatFieldData: [],
    offInvoiceCostSingleFormatData: [],
    error: false,
    salesForecastData: [],
    salesForecast: [],
    salesForecastErrorList: [],
    salesForecastFormatData: [],
    salesForecastFormatFieldData: [],
    salesForecastSingleFormatData: [],
    coverageCostData: [],
    uploadCoverageCost: [],
    uploadCoverageCostErrorList: [],
    coverageCostFormatData: [],
    coverageCostFormatFieldData: [],
    coverageCostDataSingle: [],
    coverageCostSingleFormatData: [],
    competitorData: [],
    competitorSingleData: [],
    competitorDataFormatData: [],
    competitorDataFormatFieldData: [],
    competitorDataSingleFormatData: [],
    competitorDataErrorList: [],

    multipleClaims: {},
    targetDataErrorList: [],
    inventoryDataErrorList: [],
    salesInvoiceQueryErrorList: [],
    indirectSalesInvoiceQueryErrorList: [],
    incomingClaimSuccess: [],
    masterDataDashboard: [],
    masterDataTransactionDashboard: [],
    masterDataDashboardChanges: [],
    masterDataOperation: [],
    masterDataChart: [],
    AllIndirectSalesDataList: [],
    IndirectSalesDataDetailsSingle: [],
    AllPurchaseSalesDataList: [],
    PurchaseSalesDataDetailsSingle: [],
    MasterDataConfig: [],
    ApplicationLogs: [],
    customerMasterSingleData: [],
    customerMasterXREFSingleData: [],
    buildSalesData: [],
    outGoingSingleData: []
};

const DataSetupReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_START:
            return {
                ...state,
                loading: true
            };
        case FETCH_END:
            return {
                ...state,
                loading: false
            };
        case SALES_INVOICE_QUERY_LIST:
            return {
                ...state,
                salesInvoiceQueryListData: action.data
            };
        case SALES_INVOICE_QUERY_FIELDS_SUCCESS:
            return {
                ...state,
                salesInvoiceQueryFieldsData: action.data
            };
        case SALES_DATA_QUERY_LABELS_SUCCESS:
            return {
                ...state,
                salesInvoiceQueryLabels: action.data
            };
        case SALES_INVOICE_QUERY_SINGLE_SUCCESS:
            return {
                ...state,
                salesInvoiceQuerySingleData: action.data
            };
        case SALES_INVOICE_UPLOAD_SUCCESS:
            return {
                ...state,
                salesInvoiceQueryErrorList: action.data
            };
        case ALL_SALES_DATA_LIST_SUCCESS:
            return {
                ...state,
                AllSalesDataList: action.data,

            };
        case SALES_DATA_DETAILS_SINGLE_SUCCESS:
            return {
                ...state,
                SalesDataDetailsSingle: action.data,
            };
        case SALES_DATA_UPLOAD_SUCCESS:
            return {
                ...state,
                indirectSalesInvoiceQueryErrorList: action.data
            };
        case PURCHASE_INVOICE_QUERY_LIST:
            return {
                ...state,
                purchaseInvoiceQueryListData: action.data
            };
        case PURCHASE_INVOICE_QUERY_FIELDS_SUCCESS:
            return {
                ...state,
                salesPurchaseQueryFieldsData: action.data
            };
        case PURCHASE_INVOICE_QUERY_SINGLE_SUCCESS:
            return {
                ...state,
                purchaseInvoiceQuerySingleData: action.data
            };
        case PURCHASE_INVOICE_UPLOAD_SUCCESS:
            return {
                ...state,
                purchaseInvoiceQueryErrorList: action.data
            };
        case INCOMING_CLAIMS_QUERY_LIST:
            return {
                ...state,
                incomingClaimsAllData: action.data
            };
        case TARGET_INVOICE_LIST:
            return {
                ...state,
                targetInvoiceListData: action.data
            };
        case COMMITMENT_DATA_SINGLE_SUCCESS:
            return {
                ...state,
                commitmentDataSingle: action.data
            };
        case TARGET_INVOICE_QUERY_LIST:
            return {
                ...state,
                targetInvoiceQueryListData: action.data
            };
        case TARGET_INVOICE_QUERY_FIELDS_SUCCESS:
            return {
                ...state,
                targetInvoiceQueryFieldsData: action.data
            };
        case TARGET_INVOICE_QUERY_SINGLE_SUCCESS:
            return {
                ...state,
                targetInvoiceQuerySingleData: action.data
            };
        case UPLOAD_ELIGIBILITY_RULES_SUCCESS:
            return {
                ...state,
                uploadEligibilityRulesData: action.data
            };
        case UPLOAD_ELIGIBILITY_RULES_CLEAR:
            return {
                ...state,
                uploadEligibilityRulesData: []
            };
        case MATERIAL_MASTER_QUERY_LIST:
            return {
                ...state,
                materialMasterListData: action.data
            };
        case MATERIAL_MASTER_QUERY_LIST:
            return {
                ...state,
                materialMasterErrorList: action.data
            };
        case MATERIAL_MASTER_UPLOAD_SUCCESS:
            return {
                ...state,
                materialMasterErrorLists: action.data
            };
        case MATERIAL_MASTER_ORGA_DATA:
            return {
                ...state,
                materialOrgaData: action.data
            }
        //COMPETITOR DATA
        case COMPETITOR_DATA:
            return {
                ...state,
                competitorData: action.data
            };
        case COMPETITOR_DATA_BY_ID:
            return {
                ...state,
                competitorSingleData: action.data
            }
        case COMPETITOR_DATA_FORMAT_SUCCESS:
            return {
                ...state,
                competitorDataFormatData: action.data
            };
        case COMPETITOR_DATA_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                competitorDataFormatFieldData: action.data
            };
        case COMPETITOR_DATA_FORMAT_BY_ID_SUCCESS:
            return {
                ...state,
                competitorDataSingleFormatData: action.data
            };
        case COMPETITOR_DATA_UPLOAD_SUCCESS:
            return {
                ...state,
                competitorDataErrorList: action.data
            };
        case MATERIAL_UOM_QUERY_LIST:
            return {
                ...state,
                materialUomListData: action.data
            };
        case MATERIAL_MASTER_FIELDS_SUCCESS:
            return {
                ...state,
                materialMasterQueryFieldsData: action.data
            };
        case MATERIAL_MASTER_SINGLE_FETCH_SUCCESS:
            return {
                ...state,
                materialMasterSingleData: action.data
            };
        case CUSTOMER_MASTER_FIELDS_SUCCESS:
            return {
                ...state,
                customerMasterQueryFieldsData: action.data
            };
        case CUSTOMER_MASTER_LIST:
            return {
                ...state,
                customerMasterListData: action.data
            };
        case SUPPLIER_MASTER_ORGA_DATA:
            return {
                ...state,
                supplierMasterOrgData: action.data
            }
        case CUSTOMER_MASTER_SINGLE_FETCH_SUCCESS:
            return {
                ...state,
                customerMasterSingleData: action.data
            };
        case CUSTOMER_MASTER_XREF_SINGLE_FETCH_SUCCESS:
            return {
                ...state,
                customerMasterXREFSingleData: action.data
            };
        case INDIRECT_SALES_INVOICE_QUERY_LIST:
            return {
                ...state,
                indirectSalesInvoiceQueryListData: action.data
            };
        case INDIRECT_SALES_INVOICE_QUERY_FIELDS_SUCCESS:
            return {
                ...state,
                indirectSalesInvoiceQueryFieldsData: action.data
            };
        case INDIRECT_SALES_INVOICE_QUERY_SINGLE_SUCCESS:
            return {
                ...state,
                indirectSalesInvoiceQuerysingleData: action.data
            };
        case OUTGOING_CLAIMS_QUERY_LIST:
            return {
                ...state,
                outgoingClaimsAllData: action.data
            };
        case CUSTOMER_MASTER_ORG_LIST:
            return {
                ...state,
                customerMasterOrgListData: action.data
            };
        case INCOMING_CLAIM_RESPONSE_FORMAT_FIELDS:
            return {
                ...state,
                incomingClaimFormatFields: action.data
            };
        case INCOMING_CLAIM_RESPONSE_FORMAT_SUCCESS:
            return {
                ...state,
                incomingClaimFormatData: action.data
            };
        case OUTGOING_CLAIM_DOWNLOAD_FORMAT_FIELDS:
            return {
                ...state,
                outgoingClaimDownloadFormatFields: action.data
            };
        case OUTGOING_CLAIM_DOWNLOAD_FORMAT_SUCCESS:
            return {
                ...state,
                outgoingClaimDownloadFormatData: action.data
            };
        case INVENTORY_DATA_SINGLE_SUCCESS:
            return {
                ...state,
                inventorySingleData: action.data
            };
        case INVENTORY_DATA_SUCCESS:
            return {
                ...state,
                inventoryData: action.data
            };
        case INVENTORY_DATA_FORMAT_BY_ID_SUCCESS:
            return {
                ...state,
                inventoryFormatDataById: action.data
            };
        case INVENTORY_DATA_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                inventoryFormatFieldsData: action.data
            };
        case INVENTORY_FORMAT_SUCCESS:
            return {
                ...state,
                inventoryFormatData: action.data
            };
        case DEMAND_SUPPLY_SINGLE_SUCCESS:
            return {
                ...state,
                demandSingleData: action.data
            };
        case DEMAND_SUPPLY_SUCCESS:
            return {
                ...state,
                demandSupply: action.data
            };
        case DEMAND_SUPPLY_FORMAT_BY_ID_SUCCESS:
            return {
                ...state,
                demandFormatDataById: action.data
            };
        case DEMAND_SUPPLY_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                demandFormatFieldsData: action.data
            };
        case DEMAND_FORMAT_SUCCESS:
            return {
                ...state,
                demandFormatData: action.data
            };
        case UPLOAD_FAILURE:
            return {
                ...state,
                uploadFailureData: action.data
            };
        case OFF_INVOICE_COST_ERROR_LIST_SUCCESS:
            return {
                ...state,
                offInvoiceCostErrorListData: action.data,
                offInvoiceCostErrorList: action.errorList
            };
        case OFF_INVOICE_COST_ERROR_LIST_FAILED:
            return {
                ...state,
                error: true
            };
        case OFFINVOICE_COST_FORMAT_SUCCESS:
            return {
                ...state,
                offInvoiceCostFormatData: action.data
            };
        case OFFINVOICE_COST_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                offInvoiceCostFormatFieldData: action.data
            };
        case OFFINVOICE_COST_FORMAT_BY_ID_SUCCESS:
            return {
                ...state,
                offInvoiceCostSingleFormatData: action.data
            };
        case OFFINVOICE_COST_UPLOAD_SUCCESS:
            return {
                ...state,
                offInvoiceCostErrorList: action.data
            };
        case SALES_FORECAST_DATA_SUCCESS:
            return {
                ...state,
                salesForecastData: action.data
            };
        case SALES_FORECAST_SUCCESS:
            return {
                ...state,
                salesForecastErrorList: action.data
            };
        case SALES_FORECAST_FORMAT_SUCCESS:
            return {
                ...state,
                salesForecastFormatData: action.data
            };
        case SALES_FORECAST_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                salesForecastFormatFieldData: action.data
            };
        case SALES_FORECAST_FORMAT_BY_ID_SUCCESS:
            return {
                ...state,
                salesForecastSingleFormatData: action.data
            };
        case COVERAGE_COST_DATA_SUCCESS:
            return {
                ...state,
                coverageCostData: action.data
            };
        case UPLOAD_COVERAGE_COST_SUCCESS:
            return {
                ...state,
                uploadCoverageCostErrorList: action.data
            };
        case COVERAGE_COST_FORMAT_SUCCESS:
            return {
                ...state,
                coverageCostFormatData: action.data
            };
        case COVERAGE_COST_FORMAT_FIELDS_SUCCESS:
            return {
                ...state,
                coverageCostFormatFieldData: action.data
            };
        case COVERAGE_COST_SINGLE_SUCCESS:
            return {
                ...state,
                coverageCostDataSingle: action.data
            };
        case COVERAGE_COST_FORMAT_BY_ID_SUCCESS:
            return {
                ...state,
                coverageCostSingleFormatData: action.data
            };
        case MULTIPLE_CLAIMS_DETAIL_SUCCESS:
            return {
                ...state,
                multipleClaims: action.data
            }
        case TARGET_DATA_UPLOAD_SUCCESS:
            return {
                ...state,
                targetDataErrorList: action.data
            };
        case INVENTORY_DATA_UPLOAD_SUCCESS:
            return {
                ...state,
                inventoryDataErrorList: action.data
            };
        case DEMAND_SUPPLY_UPLOAD_SUCCESS:
            return {
                ...state,
                demandSupplyErrorList: action.data
            };
        case INCOMING_CLAIMS_SUCCESS:
            return {
                ...state,
                incomingClaimSuccess: action.data
            };
        case MASTER_DATA_DASHBOARD_SUCCESS:
            return {
                ...state,
                masterDataDashboard: action.data
            };
        case MASTER_DATA_DASHBOARD_TRANSACTION_SUCCESS:
            return {
                ...state,
                masterDataTransactionDashboard: action.data
            };
        case MASTER_DATA_DASHBOARD_CHANGES_SUCCESS:
            return {
                ...state,
                masterDataDashboardChanges: action.data
            }
        case MASTER_OPERATION_DATA_SUCCESS:
            return {
                ...state,
                masterDataOperation: action.data
            }
        case MASTER_DATA_CHART_SUCCESS:
            return {
                ...state,
                masterDataChart: action.data
            }
        case ALL_INDIRECT_SALES_DATA_LIST_SUCCESS:
            return {
                ...state,
                AllIndirectSalesDataList: action.data,

            };
        case INDIRECT_SALES_DATA_DETAILS_SINGLE_SUCCESS:
            return {
                ...state,
                IndirectSalesDataDetailsSingle: action.data,
            };
        case ALL_PURCHASE_SALES_DATA_LIST_SUCCESS:
            return {
                ...state,
                AllPurchaseSalesDataList: action.data,

            };
        case PURCHASE_SALES_DATA_DETAILS_SINGLE_SUCCESS:
            return {
                ...state,
                PurchaseSalesDataDetailsSingle: action.data,
            };
        case MASTER_DATA_CONFIG_SUCCESS:
            return {
                ...state,
                MasterDataConfig: action.data,
            }
        case APPLICATION_LOGS_SUCCESS:
            return {
                ...state,
                ApplicationLogs: action.data,
            }
        case BUILD_SALES_DATA_LABELS:
            return {
                ...state,
                buildSalesData: action.data
            }
        case OUTGOING_CLAIM_GET_BY_ID:
            return {
                ...state,
                outGoingSingleData: action.data
            }
        default:
            return state;
    }
};

export default DataSetupReducer;